.home {
    &_section {
        background-color: rgb(174, 231, 228);
        color: black;
        text-align: center;
        min-height: 50rem;
        padding-top: 5rem;
    }

    &_layout {
        display: flex;
        flex-flow: row nowrap;
        padding-right: 20%;
        padding-left: 20%;
        align-items: flex-start;
    }

    &_text {
        min-height: 28rem;
        width: 50%;
        background-color: white;
        box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.55);
        padding: 1rem;
        flex-grow: 1;
    }

    &_image {
        padding-top: 15rem;
        height: 15rem;
        width: 50%;
        max-width: 30rem;
        min-width: 30rem;
        background-color: cornflowerblue;
        box-shadow: 4px 4px 6px 1px rgba(0, 0, 0, 0.55);
        text-align: center;
        margin-left: 2rem;
    }
}