.portfolio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: center;
}

.portfolio_card_container {
    margin: 1rem;
    max-width: 300px;
    min-width: 300px;
    max-height: 400px;
    min-height: 400px;
    border: 1px solid gray;
    cursor: pointer;
    overflow: hidden;
}

.portfolio_card_container:hover {
    background-color: lightgray;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.55);
    transition-duration: 500ms;
}

.portfolio_card_container.selected {
    background-color: lightgray;
}

.portfolio_card_head {
    height: 60%;
    border-bottom: 2px dotted blue;
    background-color: wheat;
    color: white;
}

.portfolio_card_copy {
    padding: 1rem;
}

.portfolio_card_title {
    padding-top: 30%;
    font-weight: bold;
    font-size: 1.5em;
    text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -1px -1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px 0px 0 #000, 0px -1px 0 #000;
}

.portfolio_card_date {
    padding-top: 6em;
    text-align: right;
    padding-right: 0.1em;
    font-weight: bold;
    text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -1px -1px 0 #000, 1px 0px 0 #000, 0px 1px 0 #000, -1px 0px 0 #000, 0px -1px 0 #000;
}

.modal_backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 500ms;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
    padding: 5rem;
}

.modal_outer {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.25rem;
    max-width: 50vw;
    width: 100%;
    position: absolute;
    top: 5rem;
    bottom: 3rem;
    margin: 0 auto;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.modal_header {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    font-weight: bold;
    font-size: large;
    display: flex;
    flex-flow: row nowrap;
}

.modal_close_x {
    color: rgba(125, 125, 125, 1);
    cursor: pointer;
    opacity: 1;
}

.modal_close_x:hover {
    transition: opacity 200ms;
    opacity: 0.5;
}

.visible {
    opacity: 1;
}

.modal_body {
    margin: 0 auto;
    padding: 2rem;
}